import React, {Component} from "react";
import './cellEdit.css'

export default class CellEdit extends Component{
    state ={
        label: this.props.value
  }

    onInputClick = (e) => {
        //this.style =  {color:'steelblue', fontWeight:'bold'} 
        this.state.label = e.target.value
        e.target.select();
    }

    onLabelChange = (e)=> {
        this.setState({
            label: e.target.value
        })
    } 

    updateElement = (e) => {
        //this.props.onMemberUpdated(this.props.iKey, e.target.value)
        this.props.onSumUpdated(e.target.id, e.target.value)
    }

    onKeyDown = (e) => {
        if (e.keyCode == 13){
            e.target.blur();
        }
    }

    render(){
        const {id} = this.props;
        return(
            <input id = {id} className="cell-label" type="number" value={this.state.label}  style={{background: 'rgba(1, 1, 1, 0)'}}
                    onClick = {this.onInputClick}
                    onChange = {this.onLabelChange}
                    onBlur = {this.updateElement}
                    onKeyDown = {this.onKeyDown}
                />
        )
    }
}