import { Component } from "react"
import "./member-with-expenses.css"
import CellEdit from "../cellEdit/cellEdit"
import minus from '../../resources/trash.png'

export default class MemberWithExpenses extends Component {
    
    state ={
          label: this.props.name
    }

    onInputClick = (e) => {
        //this.style =  {color:'steelblue', fontWeight:'bold'} 
        this.state.label = e.target.value
    }

    onLabelChange = (e)=> {
        this.setState({
            label: e.target.value
        })
    } 

    updateElement = (e) => {
        this.props.onMemberUpdated(this.props.iKey, e.target.value)
    }

    clickHandle = (e) => {
        //this.props.setPos()
    }

    deleteRow = (e) =>{
        this.props.onMemberDeleted(this.props.iKey)
    }

    onKeyDown = (e) => {
        if (e.keyCode == 13){
            e.target.blur();
        }
    }

    onSizeChange = (e) => {
        e.target.width = e.target.parentNode.width - 25;
    }

    render(){
        const {expenses, members, iKey, expArray, onSumUpdated} = this.props;
        const tdList = expenses.map((el) => {
            const ilIdRecalc = expenses.findIndex((elem)=> elem.id == el.id)
            const memIdRecalc = members.findIndex((elem)=> elem.id == iKey)
            return(
                
                <td key={el.id}>
                    <CellEdit 
                        onSumUpdated = {onSumUpdated} 
                        
                        id = {expArray[memIdRecalc][ilIdRecalc].id} //{el.id*1000+iKey} 
                        onClick = {this.clickHandle} 
                        value = {expArray[memIdRecalc][ilIdRecalc].value}
                    /></td>
            )
        });
        return(
            
            <tr><td>
               
                    <input className="table-caption-label" autoFocus type="text" placeholder = "участник" value={this.state.label}  
                        style={{background: 'rgba(1, 1, 1, 0)'}}
                        onClick = {this.onInputClick}
                        onChange = {this.onLabelChange}
                        onBlur = {this.updateElement}
                        onKeyDown = {this.onKeyDown}
                        onResize = {this.onSizeChange}
                    />
                
                    <button style={{float : "left"}} >
                        <img src={minus} type = 'button' width='20px' height='20px' onClick={this.deleteRow}></img>
                    </button>
               
                </td>{tdList}</tr>
            
        )
    }
}