import React, {Component} from "react";
import "./expense.css"
import minus from '../../resources/trash.png'

export default class Expense extends Component {
    state ={
        label: this.props.name
    }

    onInputClick = (e) => {
        //this.style =  {color:'steelblue', fontWeight:'bold'} 
        this.state.label = e.target.value
    }
  
    onLabelChange = (e)=> {
        this.setState({
            label: e.target.value
        })
    } 
  
    updateElement = (e) => {
        this.props.onExpenseUpdated(this.props.iKey, e.target.value)
    }

    deleteColumn = (e) =>{
        this.props.onExpenseDeleted(this.props.iKey)
        
    }

    onKeyDown = (e) => {
        if (e.keyCode == 13){
            e.target.blur();
        }
    }

    render(){
        const {iKey, name} = this.props
        return(
            <th>
                <input className="input-th" type="text" autoFocus placeholder = "статья расхода" value={this.state.label} style={{background: 'rgba(1, 1, 1, 0)'}}
                    onClick = {this.onInputClick}
                    onChange = {this.onLabelChange}
                    onBlur = {this.updateElement}
                    onKeyDown = {this.onKeyDown}
                />
                <button>
                    <img src={minus} type = 'button' width='20px' height='20px' onClick={this.deleteColumn}></img>
                </button>
            </th>
            
        )
    }
}