import React from "react";
import { Component } from "react";
import add from '../../resources/add.png'

export default class MemberAddForm extends Component {

    state = {
        label: ''
    }
    onSubmit =  (e)=>{
        e.preventDefault();
        this.props.onMemberAdded(this.state.label)
        this.setState({
            label: ''
        })
    }
    onLabelChange = (e)=> {
        this.setState({
            label: e.target.value
        })
    }    
    render(){
        return(<form
            onSubmit = {this.onSubmit}>          
            {/* <input
            onChange = {this.onLabelChange}
            placeholder = "Участник"
            value = {this.state.label}
            /> */}
            <button>
                <img src={add} type = 'button' width='20px' height='20px' onClick={this.deleteRow}></img>
            </button>

        </form>)
    }
}