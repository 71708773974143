import React from "react";
import { Component } from "react";
import MemberListWithExpenses from "../member-list-with-expenses/member-list-with-expenses";
import ShareExpensesService from "../../services/share-expenses-service";
import ResultsPane from "../results-pane";

export default class App extends Component {

    sharingService = new ShareExpensesService();

    maxId = 10;
    maxExId = 10;

    state = {
        membersList: [
             //{id: 0, name: ""}
            // {id: 1, name: "Ваня"}
        ],
        expensesList: [
            //{id: 0, name: ""}
            // {id: 1, name: "Пиво"}
        ],
        expensesArray: [
             //[{id: 0, value: 0}, {id: 1000, value: 0}]
            // [{id: 1, value: 0}, {id: 1001, value: 0}]
        ],

        componentUpdated: false
    
    }


    constructor (){
        super();
        this.getResources();
        //console.log("env var value fsurl:::", process.env.REACT_APP_FSURL);  
    }

    getResources(){
        this.sharingService.getMembers()
        .then((members)=>{
            this.setState({
                membersList: members
            })
        })

        this.sharingService.getExpensesArticles()
        .then((articles)=>{
            this.setState({
                expensesList: articles
            })
        })

        this.sharingService.geExpensesSums()
        .then((sums)=>{
            this.setState({
                expensesArray: sums
            })
        })
        
    }

    
    AddOneMember = (memberName)=>{
        const newMember = {
            id: this.maxId++,
            name: memberName
        }
        const memberListCopy = [...this.state.membersList];
        memberListCopy.push(newMember);

        const expensesArrayCopy = [... this.state.expensesArray]

        let ar = []
        this.state.expensesList.forEach(el => {
            ar.push({id: el.id*1000+newMember.id, value: "0"});
        });

        expensesArrayCopy.push(ar);

        this.setState((membersList)=>{
            return {membersList: memberListCopy,
            expensesArray: expensesArrayCopy
            }

        

    })}

    updateOneMember = (key, elName) => {
        this.setState(({membersList})=>{
            const idx = membersList.findIndex((el)=> el.id == key)
            let newMembersList = this.state.membersList;
            newMembersList[idx].name = elName;
            return{membersList: newMembersList}
        })
    }

    updateOneExpense = (key, elName) => {
        this.setState(({expensesList})=>{
            const idx = expensesList.findIndex((el)=> el.id == key)
            let newExpensesList = this.state.expensesList;
            newExpensesList[idx].name = elName;
            return{expensesList: newExpensesList}
        })
    }

    AddOneExpense = (expenseName)=>{
        const newExpense = {
            id: this.maxExId++,
            name: expenseName
        }
        const expenseListCopy = [...this.state.expensesList];
        expenseListCopy.push(newExpense);

        const expensesArrayCopy = [... this.state.expensesArray];
        let idx = 0;
        this.state.membersList.forEach((el)=>{
            expensesArrayCopy[idx].push({id: newExpense.id*1000+el.id, value: "0"});; 
            idx++
        })

        this.setState((expensesList)=>{
            return {
                expensesList: expenseListCopy,
                expensesArray: expensesArrayCopy
            }

        

    })}

    updateExpenseSum = (elId, sum) => {
        const yPos = Math.round(elId/1000)
        const xPos = elId - yPos*1000
        const xPosEff = this.state.membersList.findIndex((el)=> el.id == xPos);
        const yPosEff = this.state.expensesList.findIndex((el)=> el.id == yPos);
        const expensesArrayCopy = [... this.state.expensesArray];
        expensesArrayCopy[xPosEff][yPosEff] = {id: yPos*1000+xPos, value:sum}; 
        //(sum, "   ", xPos, "   ", yPos)
        return {
            expensesArray: expensesArrayCopy
        } 
    }

    deleteOneExpense = (key) => {
        this.setState(({expensesList, expensesArray})=>{
            const idx = expensesList.findIndex((el)=> el.id == key)
            const newexpensesList = [...this.state.expensesList.slice(0, idx), ...this.state.expensesList.slice(idx+1)];
            let i = 0;
            const newexpensesArray = []
            this.state.membersList.forEach((el) => {
                let expensesRow = [...this.state.expensesArray[i].slice(0, idx), ...this.state.expensesArray[i].slice(idx+1)];
                newexpensesArray.push(expensesRow);
                i++;
            })
            
            return{
                expensesList: newexpensesList,
                expensesArray: newexpensesArray
            }
        })
    }

    deleteOneMember = (key) => {
         this.setState(({membersList, expensesArray})=>{
            const idx = membersList.findIndex((el)=> el.id == key)
            const newmembersList = [...this.state.membersList.slice(0, idx), ...this.state.membersList.slice(idx+1)];
            const newexpensesArray = [...this.state.expensesArray.slice(0, idx), ...this.state.expensesArray.slice(idx+1)]
            return{
                membersList: newmembersList,
                expensesArray: newexpensesArray
            }
         })
    }

    res = {};
    inputData = {
        membersList: [],
       expensesList: [],
       expensesArray: [],
       componentUpdated: false
    };

    calculateIt = () => {
        this.sharingService.calculateSharings()
        .then((resT)=> this.res = resT);
    }

    refreshData = () => {
        this.state.componentUpdated = true;
    }

    didCalculate = () => {
        
        this.state.componentUpdated = false;
    }

    getUpdatedFlag= ()=>{
        return this.state.componentUpdated;
    }

    render(){
        const queryParams = window.location.pathname;
        const paramName = queryParams.substring(1, queryParams.indexOf("/", 1));
        if (paramName== "parties"){
            let  partyId = queryParams.substring(queryParams.indexOf("/", 1)+1);
            partyId = partyId.indexOf("/")<0?partyId:partyId.substring(0, partyId.length-1);
            this.sharingService.getDataByParty("party", partyId)
            .then((resT)=> {
                this.setState({
                    membersList: resT.membersList,
                    expensesList: resT.expensesList,
                    expensesArray: resT.expensesArray,
                    componentUpdated: resT.componentUpdated
                })
            })
            //this.inputData = resT);
            //this.setState(this.inputData)
            //console.log("INPUTDATA ::::", this.inputData);
            // const {membersList} = this.inputData;
            // console.log("mListttt::::", membersList);
            //const mList = JSON.parse(data, "membersList");S     
            //console.log(mList);
            // members = this.inputData.membersList;
            // expenses = this.inputData.expensesList;
            // expArray = this.inputData.expensesArray;
            // upt = this.inputData.componentUpdated;
         }
         let members = this.state.membersList;
         let expenses = this.state.expensesList;
         let expArray = this.state.expensesArray;
         let upt = this.state.componentUpdated;

        return(
            <div>
                <MemberListWithExpenses 
                onMemberAdded = {this.AddOneMember}
                onMemberUpdated = {this.updateOneMember} 
                onExpenseAdded = {this.AddOneExpense}
                onExpenseUpdated = {this.updateOneExpense}
                onExpenseDeleted = {this.deleteOneExpense}
                onMemberDeleted = {this.deleteOneMember}
                onSumUpdated = {this.updateExpenseSum}
                calculateIt = {this.calculateIt}
                refreshIt = {this.refreshData}
                expenses = {expenses} members= {members} expArray = {expArray}/>
                
                <ResultsPane didCalculate = {this.didCalculate} getUpdatedFlag = {this.getUpdatedFlag} data2Send = {this.state} />
            </div>
        )
    }
}