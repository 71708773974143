export default class ShareExpensesService {

    _apiUrl =  "https://share-expenses.ru/";   //"http://147.78.66.49:8081/" //

    _members = [
        {id: 0, name: ""}
        // {id: 0, name: "Аня"},
        // {id: 1, name: "Ваня"},
        // {id: 2, name: "Катя"},
        // {id: 3, name: "Валя"},
        // {id: 4, name: "Леша"},
        // {id: 5, name: "Саша"}
   ];

   _expensesArticles = [
        {id: 0, name: ""}
        // {id: 0, name: "Мясо"},
        // {id: 1, name: "Пиво"},
        // {id: 2, name: "Хлеб"},
        // {id: 3, name: "Дом"}
    ];
    _expensesSums = [
        [{id: 0, value: "0"}]
        // [{id: 0, value: "2300"}, {id: 1000, value: "0"}, {id: 2000, value: "0"}, {id: 3000, value: "0"}],
        // [{id: 1, value: "250"}, {id: 1001, value: "1300"}, {id: 2001, value: "0"}, {id: 3001, value: "0"}],
        // [{id: 2, value: "0"}, {id: 1002, value: "0"}, {id: 2002, value: "350"}, {id: 3002, value: "0"}],
        // [{id: 3, value: "0"}, {id: 1003, value: "0"}, {id: 2003, value: "0"}, {id: 3003, value: "0"}],
        // [{id: 4, value: "0"}, {id: 1004, value: "0"}, {id: 2004, value: "0"}, {id: 3004, value: "10000"}],
        // [{id: 5, value: "0"}, {id: 1005, value: "230"}, {id: 2005, value: "0"}, {id: 3005, value: "0"}]

    ];

    _sharings = [
    ];
    
    getMembers = async () => {
        return this._members;
    }

    getExpensesArticles = async () => {
        return this._expensesArticles;
    }

    geExpensesSums = async () => {
        return this._expensesSums;
    }

    getDataByParty = async (command, partyId) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const res = await fetch(`${this._apiUrl}${command}/${partyId}`, requestOptions);
        if (!res.ok) {
            throw new Error(`Couldn't fetch ${command}`)
        }
        const body = await res.json();
        return body;

    }


    calculateSharings = async (command, data) => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        const res = await fetch(`${this._apiUrl}${command}`, requestOptions);
        if (!res.ok) {
            throw new Error(`Couldn't fetch ${command}`)
        }
        const body = await res.json();
        return body;

    }
}