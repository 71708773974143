import React, {Component} from "react";
import ShareExpensesService from "../../services/share-expenses-service";
import DummyShareExpensesService from "../../services/dummy-share-expenses-service";
import './results-pane.css';

export default class ResultsPane extends Component{

    sharingService = new ShareExpensesService();

    state = {
        sharings: null
    }

    recalculate = (data2Send)=>{
        this.sharingService.calculateSharings("calculateit", data2Send)
        .then((resT)=> {this.setState({
            sharings: resT
        })});
        this.props.didCalculate();
    }

    getSharingsFromOutput = (obj) => {
        if (obj != null) {
            const strArr = obj.Sharings.split(":::")
            let str2Return = "";
            for (let i = 0; i< strArr.length; i++)
                str2Return = str2Return + strArr[i]+"\n"
            //const jsonObj = JSON.parse(obj)
            return str2Return}
        else 
            return ""
    }
    
    render(){

        const {getUpdatedFlag, data2Send} = this.props;

        const {sharings} = this.state;
        const updated = getUpdatedFlag();

        const visible = sharings&&(!updated)? "visible" : "hidden"

        const shar = {};

        // if (sharings){
        //     shar = JSON.parse(sharings);
        // }

        return(
            <div>
                <div align = "right">
                    <button className = "button"
                        onClick = {()=>this.recalculate(data2Send)}>
                        Рассчитать затраты
                    </button>
                </div>
                <div style = {{visibility: `${visible}`}}> 
                    <h1 style={{background: '#d9e0d4'}} align = "center">Таблица расчетов</h1>
                    <div><table className="tableRes" ><tbody>{getSharingsAsRow(sharings)}</tbody></table></div>
                </div>
            </div>
        )
    }
}

const getSharingsAsRow = (obj) => {
    if (obj != null) {
        const rowArr = obj.Sharings.split(":::")
        let trSet = [];
        //let tdNdx = -1;
        rowArr.forEach(row =>{
            
                const strArr = row.split("\t")
                let tdSet = [];
                strArr.forEach(element => {
                    tdSet.push(<td> {element}</td>);
                    //key={tdNdx}
                    //tdNdx--;
                });
                trSet.push(<tr>{tdSet}</tr>)
            }
        )
        
        return (
            trSet
        )}
    else 
        return (
            <tr></tr>
        )
}




