import React from "react";
import { Component } from "react";
import MemberWithExpenses from "../member-with-expenses";
import MemberAddForm from "../member-add-form";
import ExpenseAddForm from "../expense-add-form";
import Expense from "../expense/expense";
import "./member-list-with-expenses.css"

export default class MemberListWithExpenses extends Component{

        state ={
            label: ''
    }

    componentDidUpdate(){
        this.props.refreshIt();
    }
    
    render(){
        const {members, expenses, expArray, 
            onMemberAdded, onExpenseAdded, onMemberUpdated, 
            onExpenseUpdated, onSumUpdated, onExpenseDeleted,
            onMemberDeleted, calculateIt} = this.props;
        const nameElements = members.map((item)=>{
            return (
                <MemberWithExpenses 
                    key = {item.id} 
                    iKey = {item.id} 
                    name = {item.name}
                    onMemberUpdated = {onMemberUpdated} 
                    expenses = {expenses}
                    members = {members}
                    expArray = {expArray}
                    onSumUpdated = {onSumUpdated}
                    onMemberDeleted = {onMemberDeleted}
                />
            )})
        const expenseElements = expenses.map((item) =>{
            return (
                <Expense
                    key = {item.id} 
                    iKey = {item.id} 
                    name = {item.name}
                    onExpenseUpdated = {onExpenseUpdated} 
                    onExpenseDeleted = {onExpenseDeleted}
                    
                />
            )})
        return(

            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <table className="table">
                                    <caption>Таблица расходов</caption>
                                    <tbody>
                                        <tr><th>Имя</th>{expenseElements}</tr> 
                                        {nameElements}
                                    </tbody>
                                </table>
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <div style={{ visibility: 'hidden', padding: '5px', lineHeight: 3}}>
                                    h-n
                                </div>
                                <div style={{padding: '5px' }}>
                                    <ExpenseAddForm onExpenseAdded = {onExpenseAdded} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td >
                                
                                <div align = "left">
                                    <MemberAddForm 
                                        onMemberAdded = {onMemberAdded}
                                    />
                                </div>
                                {/* <div align = "right">
                                    <button className = "button"
                                    onClick = {calculateIt}>
                                        Рассчитать затраты
                                    </button>
                                </div> */}
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
                
                
               
            </div>
        )
    }
}